import React from "react";
import { Helmet } from "react-helmet-async";

const NotFound = () => {
  return (
    <div className="bg-[#151515] text-white flex flex-col gap-0 md:gap-5 items-center justify-center h-auto md:h-screen px-2 py-5 md:px-0 md:py-0">
      <Helmet>
        <title>Page Not Found | Best Handyman Ever</title>
      </Helmet>
      <div className="icon mb-5">
        <img
          src="https://besthandymanever.com/logo192.png"
          alt="Handyman Icon"
          style={{ width: "120px" }}
        />
      </div>

      <h1 className="text-[#F8981D] font-bold my-[10px] text-[2em] sm:text-[3em] text-center">
        Oops! This Page Needs a Patch!
      </h1>

      <p className="text-center text-lg mb-5">
        Looks like the page you’re looking for doesn't exist, but don't worry, I
        can fix that! Let me guide you back to what you need.
      </p>

      <div className="w-full flex flex-col md:flex-row gap-2 md:gap-4 items-center justify-center my-8">
        <a
          href="https://besthandymanever.com/"
          className="hover:bg-[#e07d1a] bg-[#F8981D] text-[#151515] rounded-md px-[20px] py-3 w-full md:w-auto flex items-center justify-center"
        >
          Return Home
        </a>
        <a
          href="tel:415-712-0894"
          className="hover:bg-[#e07d1a] bg-[#F8981D] text-[#151515] rounded-md px-[20px] py-3 w-full md:w-auto flex items-center justify-center"
        >
          Contact Me
        </a>
        <a
          href="https://besthandymanever.com/#services"
          className="hover:bg-[#e07d1a] bg-[#F8981D] text-[#151515] rounded-md px-[20px] py-3 w-full md:w-auto flex items-center justify-center"
        >
          View My Services
        </a>
        <a
          href="mailto:besthandymaneversf@gmail.com?subject=Website Issue Report&body=Please describe the issue you are experiencing and include screenshots if possible (Windows+PrtScr)."
          className="hover:bg-[#e07d1a] bg-[#FFFFFF] text-[#151515] rounded-md px-[20px] py-3 border border-[#F8981D] w-full md:w-auto flex items-center justify-center"
        >
          Report a Problem
        </a>
      </div>

      <footer className="w-full text-center text-sm sm:text-base mt-5 text-[#F8981D] md:absolute md:bottom-5">
        &copy; 2024 Best Handyman Ever. Your Bay Area Handyman.
      </footer>
    </div>
  );
};

export default NotFound;
