import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getRentalProductsAsync,
  showRentalProducts,
} from "../../app/productSlice";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./style/rental.css";
import { EffectCoverflow, Pagination } from "swiper/modules";

const Rental = ({ bookAppointment }) => {
  const dispatch = useDispatch();
  const rentalProductList = useSelector(showRentalProducts);

  const stars = ["1", "2", "3", "4", "5"];

  useEffect(() => {
    dispatch(getRentalProductsAsync());
  }, [dispatch]);

  return (
    <div className="w-full flex flex-col items-center">
      <h2 className="text-white border-b-4 border-[#F8981D] bg-black px-2 py-1 font-black text-[16px] sm:text-[20px] leading-[23.44px] mt-[49px]">
        TOOL RENTALS
      </h2>
      <h3 className="text-[#151515] font-bold text-[32px] sm:text-[40px] leading-[46.88px] mt-[17px]">
        Featured Rentals
      </h3>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
        initialSlide={1}
      >
        {rentalProductList
          ? rentalProductList.map((item, ind) => (
              <SwiperSlide key={ind}>
                <div>
                  <div className="w-full bg-[#151515]">
                    <img
                      className="w-full h-[250px] object-contain"
                      src={item.image}
                      alt="item"
                      loading="lazy"
                    />
                  </div>
                  <div className="w-full bg-[#1b1414] px-[10px] mt-[10px] pb-[12px]">
                    <div
                      className="hover:underline hover:cursor-pointer text-[#FFFFFF] text-[20px] font-semibold mt-[10px]"
                      onClick={() => bookAppointment(`${item.shortName}`)}
                    >
                      {item.name}
                    </div>
                    <div className="flex justify-between mt-[12px]">
                      <div
                        className="text-[#F8981D] text-[16px] font-black leading-[19px] hover:cursor-pointer"
                        onClick={() => bookAppointment(`${item.shortName}`)}
                      >
                        <div className="underline">
                          {item.priceObj?.recurring?.interval_count}{" "}
                          {item.priceObj?.recurring?.interval} Rental
                        </div>
                        <div className="underline">
                          ${item.priceObj.unit_amount / 100}
                        </div>
                      </div>
                      <div className="flex justify-between">
                        {stars.map((star, ind) => {
                          return (
                            <img
                              key={ind}
                              className="w-[13px] h-[13px]"
                              src="assets/img/star.webp"
                              alt="star"
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))
          : null}
      </Swiper>
    </div>
  );
};

export default Rental;
