import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getGalleryByCategoryAsync, showGallery } from "../../app/gallerySlice";

const Gallery = () => {
  const dispatch = useDispatch();
  const galleries = useSelector(showGallery);
  const [galleryData, setGalleryData] = useState([]);

  useEffect(() => {
    dispatch(getGalleryByCategoryAsync());
  }, [dispatch]);

  useEffect(() => {
    if (galleries) {
      let galleriesData = [];
      galleries.forEach((gallery) => {
        galleriesData.push({
          id: gallery._id,
          category: gallery.bookingCategory._id,
          image: gallery.filePaths[0].afterImage
            ? gallery.filePaths[0].afterImage?.path
            : gallery.filePaths[0].beforeImage?.path,
          alt: gallery.filePaths[0].afterImage
            ? gallery.filePaths[0].afterImage?.description
            : gallery.filePaths[0].beforeImage?.description,
        });
      });
      setGalleryData(galleriesData.slice(0, 8));
    }
  }, [galleries]);

  return (
    <div
      id="gallery"
      className="w-full flex flex-col items-center mt-[54px] bg-no-repeat bg-cover bg-center pb-10"
      style={{
        backgroundImage: `url(assets/img/handyman-projects-cover.webp)`,
      }}
      aria-label="Handyman projects cover image"
    >
      <h2 className="text-[#F8981D] text-[16px] sm:text-[20px] font-black leading-[23px] mt-[84px]">
        Recent Handyman Projects
      </h2>
      <h3 className="text-[#FFFFFF] text-[32px] sm:text-[40px] font-bold leading-[47px] mt-[18px] text-center">
        Some Of My Recent Projects
      </h3>
      <div className="w-full max-w-[1440px] px-[20px] lg:px-[40px] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-[40px]">
        {galleryData &&
          galleryData.map((gallery) => (
            <div key={gallery.id} className="flex">
              {gallery?.image && (
                <img
                  className="w-full h-[300px] max-w-full object-cover"
                  src={`${process.env.REACT_APP_API_BASE_URL}${gallery.image}`}
                  alt={gallery.alt || "gallery"}
                  loading="lazy"
                />
              )}
            </div>
          ))}
      </div>
      <Link to="/gallery">
        <button className="w-fit bg-black px-[29px] py-[13px] mt-[40px] text-white font-bold text-center mx-auto lg:mx-[0px] border-2 border-[#F8981D] hover:bg-[#F8981D]">
          SEE ALL GALLERIES
        </button>
      </Link>
    </div>
  );
};

export default Gallery;
