import React from "react";

const Appointment = ({ bookAppointment }) => {
  return (
    <div
      id="appointment"
      className="w-full flex flex-col lg:flex-row justify-between items-center px-[20px] lg:px-[80px] mt-[59px] mb-[50px]"
    >
      <div className="basis-1/2 flex flex-col items-center mr-[0px] lg:mr-[29px] mb-[30px] lg:mb-0">
        <div className="w-full text-[#151515] text-[40px] font-bold">
          <div className="text-[#151515] text-[32px] sm:text-[40px] font-black text-center">
            Book Appointment
          </div>
          <div
            className="w-full mt-[30px] hover:cursor-pointer"
            onClick={bookAppointment}
          >
            <img
              className="w-[580px] h-auto"
              src="/assets/img/calendar.webp"
              alt="calendar"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div className="basis-1/2 hidden lg:flex justify-between">
        <div className="basis-1/2 lg:mb-[164px] mr-[27px]">
          <img
            className="w-[292px] h-[559px]"
            src="/assets/img/book1.webp"
            alt="book"
            loading="lazy"
          />
        </div>
        <div className="basis-1/2 lg:mt-[164px]">
          <img
            className="w-[292px] h-[559px]"
            src="/assets/img/book2.webp"
            alt="book"
            loading="lazy"
          />
        </div>
      </div>
    </div>
  );
};

export default Appointment;
